import { inject, Injectable } from '@angular/core';
import { StateService } from './state.service';
import { environment } from '../../environments/environment';
import { ScriptService } from './script.service';
import { BehaviorSubject, combineLatest, filter, fromEvent, take } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { IS_SERVER } from '../providers/is-platform.provider';
import { SeoService } from './seo.service';
import { CookiebotService } from './cookiebot.service';

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const SR: any;

const SYNERISE_INIT_EVENT_NAME = 'syneriseInitialised';
const SYNERISE_SCRIPT_ID = 'synerise';
const TRACKING_KEYS = {
  en: '90c32277-abea-4eda-9e44-04e4727c6255',
  sk: '2d3a6ae4-7b45-4acd-9426-7591aa57ce87',
  cs: '851d3f2f-4d9a-482e-8e85-0a5a7c6a773f',
} as Readonly<Record<string, string>>;

@Injectable({
  providedIn: 'root',
})
export class SyneriseService {
  private readonly state = inject(StateService);
  private readonly script = inject(ScriptService);
  private readonly document = inject(DOCUMENT);
  private readonly isEnabled = environment.isSyneriseEnabled;
  private readonly isServer = inject(IS_SERVER);
  private readonly seo = inject(SeoService);
  private readonly cookiebot = inject(CookiebotService);
  private readonly isActive$ = new BehaviorSubject<boolean>(true);

  init(): void {
    if (!this.isEnabled || this.isServer) {
      return;
    }

    this.cookiebot.consents$.subscribe(({ statistics }) => {
      if (statistics) {
        this.activate();
      } else {
        this.deactivate();
      }
    });
  }

  private activate(): void {
    this.isActive$.next(true);
    // this won't work if one day we decide not to reload the whole page when user changes locale
    // take(1) is not the problem here - the problem is that synerise lacks the option to
    // re-initialize with a different tracking code
    this.state.activeLocale$.pipe(take(1)).subscribe((locale) => {
      fromEvent(this.document, SYNERISE_INIT_EVENT_NAME)
        .pipe(take(1))
        .subscribe(() => {
          combineLatest([this.seo.ogTagsChanged$, this.isActive$])
            .pipe(filter(([, isActive]) => isActive))
            .subscribe(this.onPageVisit);
        });

      this.script.createAndAppend({
        body: this.createScriptBody(TRACKING_KEYS[locale]),
        id: SYNERISE_SCRIPT_ID,
      });
    });
  }

  private deactivate(): void {
    this.isActive$.next(false);
  }

  private onPageVisit = (): void => {
    SR.event.pageVisit().then(function () {
      SR.dynamicContent.get();
    });
  };

  private createScriptBody(trackingKey: string): string {
    return `
  function onSyneriseLoad() {
      SR.init({
          trackerKey: "${trackingKey}",
          customPageVisit: true,
          dynamicContent: {
            virtualPage: true
          },
          disableWebPush: true
        });
      document.dispatchEvent(new CustomEvent('${SYNERISE_INIT_EVENT_NAME}'));
  }

  (function(s,y,n,e,r,i,se){s["SyneriseObjectNamespace"]=r;s[r]=s[r]||[],
   s[r]._t=1*new Date(),s[r]._i=0,s[r]._l=i;var z=y.createElement(n),
   se=y.getElementsByTagName(n)[0];z.async=1;z.src=e;se.parentNode.insertBefore(z,se);
   z.onload=z.onreadystatechange=function(){var rdy=z.readyState;
   if(!rdy||/complete|loaded/.test(z.readyState)){s[i]();z.onload = null;
   z.onreadystatechange=null;}};})(window,document,"script", "//web.snrbox.com/synerise-javascript-sdk.min.js", "SR", "onSyneriseLoad");
`;
  }
}
