import { inject, Injectable } from '@angular/core';
import {
  fromEvent,
  map,
  Observable,
  ReplaySubject,
  retry,
  shareReplay,
  startWith,
  Subject,
  take,
} from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { IS_SERVER } from '../providers/is-platform.provider';
import { switchMap } from 'rxjs/operators';

export type CookieConsents = {
  necessary: boolean;
  preferences: boolean;
  statistics: boolean;
  marketing: boolean;
};

declare const Cookiebot: {
  consent: {
    method: string | null;
  } & CookieConsents;
};

const COOKIEBOT_CONSENT_READY_EVENT = 'CookiebotOnConsentReady';
const COOKIEBOT_ON_LOAD = 'CookiebotOnLoad';

@Injectable({
  providedIn: 'root',
})
export class CookiebotService {
  private readonly cookiesChoiceDone = new ReplaySubject<void>();
  private readonly document = inject(DOCUMENT);
  private readonly isServer = inject(IS_SERVER);
  private cookiebotLoaded$ = new Subject<boolean>().asObservable();
  private consents = new ReplaySubject<CookieConsents>();

  cookiesChoiceDone$ = this.cookiesChoiceDone.asObservable();
  consents$ = this.consents.asObservable();

  constructor() {
    if (this.isServer) {
      return;
    }

    this.consentChanges().subscribe(this.updateConsentsState);

    const cookiebotScript = this.document.getElementById('Cookiebot');
    this.cookiebotLoaded$ = fromEvent(cookiebotScript!, 'load', {
      passive: true,
      once: true,
    }).pipe(
      startWith(null),
      map(() => {
        if (Cookiebot) {
          return true;
        } else {
          throw new Error();
        }
      }),
      retry({ count: 5, delay: 500 }),
      take(1),
      shareReplay(1),
    );
  }

  afterUserInitialChoice(): Observable<void> {
    return this.cookiebotLoaded$.pipe(
      take(1),
      switchMap(() => {
        if (!Cookiebot.consent || !Cookiebot.consent.method) {
          window.addEventListener(
            COOKIEBOT_CONSENT_READY_EVENT,
            () => this.cookiesChoiceDone.next(),
            {
              passive: true,
              once: true,
            },
          );
        } else {
          this.cookiesChoiceDone.complete();
        }

        return this.cookiesChoiceDone$;
      }),
    );
  }

  private consentChanges(): Observable<Event> {
    return fromEvent(window, COOKIEBOT_ON_LOAD);
  }

  private updateConsentsState = (): void => {
    this.consents.next({
      necessary: true,
      marketing: Cookiebot.consent.marketing,
      statistics: Cookiebot.consent.statistics,
      preferences: Cookiebot.consent.preferences,
    });
  };
}
